<template>
	<div :class="themeClass">
		<div class="myinviteBox">
			<div class="flex-row flex-row-space-between-center invite-top-box">
				<div class="centerBgc flex-row"></div>
				<div class="">
					<div class="semicircle bg-fff text-center"><span class="wo_invite_text font-size16 font-weight700">我的邀请</span></div>
					<div class="content_top_bgcff bg-fff radius10">
						<div class="jucai_weweima" style="padding-top: 44rpx">
							<!-- <div id="qrCodeDivID"></div> -->
							<img src="../../../assets/u42.jpg" />
						</div>
						<div class="downlode_text font-size13 font-color-999 text-center">下载聚材通平台APP</div>
						<div class="flex-row invite_ma_box">
							<div class="flex-row">我的邀请码：</div>
							<div class="flex-row invite_ma font-weight700 font-size18">{{ getUserInfo.fInvitationCode }}</div>
							<div class="flex-row pointer" v-bind:title="copytitle"><i class="el-icon-document-copy font-size15" v-copy="getUserInfo.fInvitationCode"></i></div>
						</div>
						<div class="invite_btn font-weight700 text-center font-color-fff pointer" @click="invited">邀请好友</div>
					</div>
				</div>
			</div>
			<div>
				<div class="position-r">
					<div class="invite_top_text font-weight700 text-center " >-- 已邀请{{ totalCount }}位好友(显示最近10位) --</div>
          <div @click="morethan" class="than_more" ><span >更多></span></div>
				</div>
				<div class="u_srcoll_box" :class="{ 'animate-up': animateUp }" v-if="listData.length != 0">
					<div class="srcoll-item flex-row" >
						<div class="flex-row">
							<ul class="infinite-list-content" >
								<li v-for="(item, index) in listData" :key="index" class="list-content-item flex-row-space-between-center" style="margin: 10px 10px 10px 0 !important;">
									<div class="flex-row-space-between-center padding-r-10" style="flex: 1;">
										<div class="flex-row-align-center">
											<div class="u_srcoll_img flex-row"><img :src="item.fFileName" width="100%" /></div>
											<div class="u_srcoll_name font-size12 padding-l-10 hide-text">{{ item.fUserName }}</div>
										</div>
										<div class="u_srcoll_date font-size12">{{ item.fCreateTime }}</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="u_srcoll_box" v-else>
					<div class="flex-column-align-center text-center martop50"><span class="font-color-666 font-size14">暂无邀请人</span></div>
				</div>
			</div>

			<div class="inviteRoles-box margin0-auto text-left font-size12">
				<div class="padding10">
					<div class="inviteRoles-text font-size14">邀请规则：</div>
					<div class="invite_p font-size12 text-left">
            <!-- fInviteRuleQY:"",//新企业客户注册成功后 自己获取金币
			      fInviteRuleGR:'',//新个人客户注册成功后 自己获取金币
			      fInvitedRule:"",//新个人客户/企业客户注册成功后 新客户获取金币
			      fFirstInviteDeliverRuleD:"",//个人/企业客户发货后 发货客户获取的金币
			      fFirstInviteDeliverRule:"" //个人/企业客户发货后 自己获取的金币 -->
						<!-- <p v-if='fInviteRuleGR == fInvitedRule'> -->
							<!-- 1、新客户注册填写您的邀请码，注册成功后，您将和新客户每人获得{{fInviteRule}}金币。 -->
							<!-- 1、新的个人客户注册填写您的邀请码，注册成功后，您将和新客户每人获得{{fInviteRuleGR}}金币。
						</p> -->
						<!-- <p v-else> -->
							<!-- 1、您将获得{{fInviteRule}}金币，新客户将客户{{fInvitedRule}}金币。 -->
							<!-- 2、新的个人客户注册填写您的邀请码，注册成功后，您将获得{{fInviteRuleGR}}金币奖励，新客户将获得{{fInvitedRule}}金币奖励。
						</p> -->
						<!-- <p v-if='fInviteRuleQY == fInvitedRule'> -->
							<!-- 1、新客户注册填写您的邀请码，注册成功后，您将和新客户每人获得{{fInviteRule}}金币。 -->
							<!-- 1、新的个人客户注册填写您的邀请码，注册成功后，您将和新客户每人获得{{fInviteRuleQY}}金币。
						</p>
						<p v-else> -->
							<!-- 1、您将获得{{fInviteRule}}金币，新客户将客户{{fInvitedRule}}金币。 -->
							<!-- 2、新的企业客户注册填写您的邀请码，注册成功后，您将获得{{fInviteRuleQY}}金币奖励，新客户将获得{{fInvitedRule}}金币奖励。
						</p> -->
						<p>
              1、新客户注册填写您的邀请码，注册成功后，邀请个人客户，您将和新客户每人获得{{fInviteRuleGR}}金币，邀请企业客户，您将和新客户每人获得{{fInviteRuleQY}}金币。
							<!-- 2、通过您的邀请码注册成功的客户，在其发货后，您将额外获得{{fFirstInviteDeliverRule}}金币的奖励，发货客户也将额外获得{{fFirstInviteDeliverRuleD}}金币的奖励。 -->
						    <!-- 3、通过您的邀请码注册成功的个人客户，在其发货后，您将额外获得{{fFirstInviteDeliverRule}}金币的奖励，发货客户也将额外获得{{fFirstInviteDeliverRuleD}}金币的奖励。 -->
						</p>
						<p>
              2、通过您的邀请码注册成功的客户，在其发货后，您将额外获得{{fFirstInviteDeliverRule}}金币的奖励，发货客户也将额外获得{{fFirstInviteDeliverRuleD}}金币的奖励。
							<!-- 3、您通过邀请码邀请客户获得金币奖励上限为100位/年，当年邀请超过100位客户后您将无法继续获得金币奖励，被邀请人奖励仍正常发放。 -->
						    <!-- 4、通过您的邀请码注册成功的企业客户，在其发货后，您将额外获得{{fFirstInviteDeliverRule}}金币的奖励，发货客户也将额外获得{{fFirstInviteDeliverRuleD}}金币的奖励。 -->
						</p>
						<p>
							3、您通过邀请码邀请客户获得金币奖励上限为100位/年，当年邀请超过100位客户后，您将无法继续获得金币奖励，被邀请人奖励仍正常发放。
						</p>
						<p>
							4、以上规则最终解释权归聚材通平台所有。
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import QRCode from 'qrcodejs2';
export default {
	data() {
		return {
			copytitle: '复制',
			animateUp: false,
			timer: null,
			count: 0,
			totalCount: 0,
			// scrollTop: 0,
			// old: {
			//   scrollTop: 0,
			// },
			// show: false,
			// transparent: false,
			// erweimaSrc: "/static/image/PersonalCenter/mineInvite/jucai_erweima.png",
			// listData: [{
			// 		fFileName: "//img14.360buyimg.com/n7/jfs/t1/60319/15/6105/406802/5d43f68aE9f00db8c/0affb7ac46c345e2.jpg",
			// 		fUserName: "小小哈1",
			// 		userDate: "2020.09.10",
			// 	},
			// 	{
			// 		fFileName: "//img14.360buyimg.com/n7/jfs/t1/60319/15/6105/406802/5d43f68aE9f00db8c/0affb7ac46c345e2.jpg",
			// 		fUserName: "大鲤鱼2",
			// 		userDate: "2020.09.11",
			// 	},
			// 	{
			// 		fFileName: "//img14.360buyimg.com/n7/jfs/t1/60319/15/6105/406802/5d43f68aE9f00db8c/0affb7ac46c345e2.jpg",
			// 		fUserName: "爱奇艺3",
			// 		userDate: "2020.09.12",
			// 	},
			// 	{
			// 		fFileName: "//img14.360buyimg.com/n7/jfs/t1/60319/15/6105/406802/5d43f68aE9f00db8c/0affb7ac46c345e2.jpg",
			// 		fUserName: "微笑吧4",
			// 		userDate: "2020.09.13",
			// 	},
			// 	{
			// 		fFileName: "//img14.360buyimg.com/n7/jfs/t1/60319/15/6105/406802/5d43f68aE9f00db8c/0affb7ac46c345e2.jpg",
			// 		fUserName: "丁真5",
			// 		userDate: "2020.09.14",
			// 	},
			// 	{
			// 		fFileName: "//img14.360buyimg.com/n7/jfs/t1/60319/15/6105/406802/5d43f68aE9f00db8c/0affb7ac46c345e2.jpg",
			// 		fUserName: "欣欣6",
			// 		userDate: "2020.09.15",
			// 	},
			// 	{
			// 		fFileName: "//img14.360buyimg.com/n7/jfs/t1/60319/15/6105/406802/5d43f68aE9f00db8c/0affb7ac46c345e2.jpg",
			// 		fUserName: "格格7",
			// 		userDate: "2020.09.16",
			// 	},
			// 	{
			// 		fFileName: "//img14.360buyimg.com/n7/jfs/t1/60319/15/6105/406802/5d43f68aE9f00db8c/0affb7ac46c345e2.jpg",
			// 		fUserName: "艳艳8",
			// 		userDate: "2020.09.17",
			// 	},
			// ],
			listData: [],
			number2: -1,
			fInviteRuleQY:"",//新企业客户注册成功后 自己获取金币
			fInviteRuleGR:'',//新个人客户注册成功后 自己获取金币
			fInvitedRule:"",//新个人客户/企业客户注册成功后 新客户获取金币
			fFirstInviteDeliverRuleD:"",//个人/企业客户发货后 发货客户获取的金币
			fFirstInviteDeliverRule:"" //个人/企业客户发货后 自己获取的金币
		};
	},
	computed: {
		...mapGetters(['getThemeName', 'getUserInfo']),
		themeClass() {
			return `theme-${this.getThemeName}`;
		}
	},
	mounted() {
		// this.timer = setInterval(this.scrollAnimate, 3000);
		this.getUserList();
		// this.createQrCode()
		this.getCurretRuleNew();
	},
	methods: {
    //点击更多去跳转
    morethan() {
      this.$router.push({
										path: '/PersonalContent/moremyinvite',
										query: '',
			});
    },
		getCurretRuleNew() {
			this.ApiRequestPostNOMess('/api/mall/ebactivity/firstInvite-deliver-rule/get-curret-rule-new', {
			}).then(
				res => {
					console.log(res)
					this.fInviteRuleQY= res.obj.fInviteRuleQY.fGoldCoinAmount
					this.fInviteRuleGR= res.obj.fInviteRuleGR.fGoldCoinAmount
					this.fInvitedRule = res.obj.fInvitedRule.fGoldCoinAmount  // 被邀请人不分
					this.fFirstInviteDeliverRuleD =res.obj.fFirstInviteDeliverRuleD.fGoldCoinAmount  //发货客户
					this.fFirstInviteDeliverRule  =res.obj.fFirstInviteDeliverRule.fGoldCoinAmount
				},
				error => {}
			);
		},
		load() {},
		aaa() {
			// let odiv = document.getElementsByClassName("u_srcoll_box");
			// console.log(odiv.style.t, "odiv.style.topodiv.style.top");
		},
		scrollAnimate() {
			this.animateUp = true;
			setTimeout(() => {
				// let odiv = document.querySelector(".srcoll-item");
				// odiv.style.top ="-"+ odiv.offsetTop + 50 + "px";
				console.log(setTimeout, 'setTimeout');
				// this.listData.push(this.listData[0]);
				// this.listData.shift();
				this.animateUp = false;
			}, 2000);
		},
		getUserList() {
			this.ApiRequestPostNOMess('api/mall/ebcustomer/baseinfo/get-invite-list', {
				maxResultCount: 100,
				filter: '',
				sorting: '',
				skipCount: 0
			}).then(
				res => {
					this.listData = [];

					for (let item of res.obj.items) {
						item.fCreateTime = this.getDate(item.fCreateTime);
						if (!item.fFileName) {
							item.fFileName = require('@/assets/personal.png');
						}
					}
          console.log(res.obj.items)
					this.listData = res.obj.items.slice(0,10);
					this.totalCount = res.obj.totalCount;
				},
				error => {}
			);
		},
		destroyed() {
			clearInterval(this.timer);
		},
		//邀请好友
		invited() {
			console.log('邀请好友');
			// 复制提货信息
			// let refCopyInfo = '邀请连接';
			let refCopyInfo =this.getUserInfo.fInvitationCode
			var input = document.createElement('input'); // js创建一个input输入框
			input.value = refCopyInfo; // 将需要复制的文本赋值到创建的input输入框中，this.ruleForm.url这个是我要复制的内容
			document.body.appendChild(input); // 将输入框暂时创建到实例里面
			input.select(); // 选中输入框中的内容
			document.execCommand('Copy'); // 执行复制操作
			document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
			// this.tipsInfo("复制邀请连接成功")
		},
		// 生成二维码
		createQrCode() {
			let refQRID = 'qrCodeDivID';
			let RQDpcument = document.getElementById(refQRID);
			// 重置元素内容
			RQDpcument.innerHTML = '';
			this.QRCode = new QRCode(RQDpcument, {
				width: 80,
				height: 80,
				text: '下载材财通连接', // 二维码地址 TODO
				colorDark: '#000', // 二维码颜色
				colorLight: '#fff' // 二维码背景色
			});
		}
	}
};
</script>
<style scoped lang="scss">
.martop50 {
	margin-top: 14%;
}

.position-r {
	position: relative;
}

.padding10 {
	padding: 10px;
}

.margin0-auto {
	margin: 0 auto;
}

.myinviteBox {
	margin-top: 10px;
	width: 916px;
	min-height: 898px;
	background: url(../../../assets/imgs/myInvite/invite_bgc.png) no-repeat 0 0;
	background-size: 100% 100%;
}

.invite-top-box {
	padding: 37px 58px 10px 58px;
}

.centerBgc {
	width: 343px;
	height: 288px;
	opacity: 0.7;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	padding-top: 50px;
	background-image: url(../../../assets/imgs/myInvite/invite_centerBgc.png);
}

.u_box_bgc {
	width: 100%;
}

.semicircle {
	width: 100px;
	height: 50px;
	margin: 0 auto;
	border-radius: 55px 55px 0 0;
	border-bottom: none;

	.wo_invite_text {
		width: 65px;
		height: 16px;
		color: #fb7763;
		position: relative;
		left: 0%;
		top: 43%;
	}
}

.content_top_bgcff {
	width: 316px;
	height: 250px;
	margin: 0 auto;

	.jucai_weweima {
		width: 80px;
		margin: 0 auto;
		padding-top: 18px;
	}

	.downlode_text {
		width: 200px;
		line-height: 25px;
		margin: 0 auto;
	}

	.invite_ma_box {
		width: 200px;
		margin: 10px auto;
		line-height: 18px;

		.invite_ma {
			color: #fb7763;
			margin-right: 15px;
		}
	}

	.invite_btn {
		width: 240px;
		background: #fb7763;
		border-radius: 40px;
		margin: 30px auto;
		line-height: 40px;
	}
}

.invite_top_text {
	width: 266px;
	background: #fbc8b3;
	border-radius: 20px 20px 0 0;
	color: #e2765f;
	font-size: 14px;
	line-height: 30px;
	margin: 0 auto;
	position: absolute;
	top: 8px;
	// left: 41%;
  // transform: translateY(-50%);
  // left: 50%; top: 50%;  
  left: 50%; 
  transform: translate(-50%, -50%);  
	z-index: 999 !important;
}
.than_more{
  // border: 1px solid;
  height: 30px;
  position: absolute;
  right: 35px;
  width: 236px;
  text-align: right;
  top: 4px;
  color: #e2765f;
  font-size: 14px;
  font-weight: 900;
  z-index: 999 !important;
  cursor:pointer
}

.u_srcoll_box {
	position: relative;
	z-index: 997;
	width: 871px;
	height: 359px;//外层div高度修改 展示10位
	background: rgba(251, 200, 179, 0.4);
	border-radius: 6px;
	scrollbar-width: none;
	/* firefox */
	-ms-overflow-style: none;
	/* IE 10+ */
	overflow-x: hidden;
	overflow-y: auto;
	margin: 0px 23px 10px 23px;

	.animate-up {
		transition: all 0.5s ease-in-out;
		transform: translateY(-211px);
	}

	.srcoll-item {
		position: absolute;
		left: 2%;
		top: 10px;
	}

	.infinite-list-content {
		width: 871px;
		height: 331px;
		overflow: auto;
		overflow-y: scroll;
		overflow-x: hidden;
		margin: 8px 10px 0px 0;

		.list-content-item {
			float: left;
			width: 415px;
			height: 45px;
			background: rgba(255, 255, 255, 0.4);
			border-radius: 2px;
			margin: 10px 10px 10px 0;

			.u_srcoll_img {
				width: 36px;
				height: 36px;
				border-radius: 50%;
				overflow: hidden;
				margin-left: 10px;
				color: #e2765f;
			}

			.u_srcoll_name {
				width: 260px;
				height: 36px;
				line-height: 36px;
				color: #e2765f;
			}

			.u_srcoll_date {
				width: 70px;
				height: 36px;
				line-height: 36px;
				color: #e2765f;
			}
		}
	}
}

.u_srcoll_box ::-webkit-scrollbar {
	display: none;
	/* Chrome Safari */
}

.inviteRoles-box {
	width: 871px;
	// height: 122px;
	background: rgba(255, 206, 176, 0.4980392156862745);
	border-radius: 10px;
	color: #3b3155;

	.inviteRoles-text {
		color: #3b3155;
		line-height: 24px;
	}

	.invite_p {
		color: #3b3155;
		line-height: 20px;
	}
}

.u_srcoll_box .infinite-list-content .list-content-item:first-child {
	margin: 0 10px 10px 0;
}
</style>
